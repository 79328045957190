@import "./sass/variables";
@import "~react-big-calendar/lib/css/react-big-calendar.css";

.ant-btn-primary {
  border-color: var(--color-primary-1);
  background: var(--color-primary-1);
  &:not(:disabled):hover {
    background: var(--color-primary-2) !important;
    border-color: var(--color-primary-1);
    color: var(--color-white-1) !important;
  }
  &:not(:disabled):focus {
    background: var(--color-primary-2) !important;
    border-color: var(--color-primary-1);
    color: var(--color-white-1) !important;
  }
}
.ant-btn-background-ghost.ant-btn-primary {
  border-color: var(--color-primary-1);
  color: var(--color-primary-1);
  &:not(:disabled):hover {
    background: transparent !important;
    border-color: var(--color-primary-2);
    color: var(--color-primary-2) !important;
  }
  &:not(:disabled):focus {
    background: transparent !important;
    border-color: var(--color-primary-2);
    color: var(--color-primary-2) !important;
  }
}

.ant-btn:focus,
.ant-btn:hover {
  color: var(--color-primary-1);
  border-color: var(--color-primary-1);
  background: #fff;
}

.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgb(255 156 24 / 38%);
  border-color: var(--color-primary-1);
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--color-primary-1);
}

.ant-menu-item {
  font-weight: 600;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--color-white-1);
  > img {
    background: var(--color-primary-1);
  }
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span,
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: var(--color-primary-1);
  // margin: 0px auto;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 80%;
  margin: 24px auto;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px calc(50% - 21px);
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  padding: 0 5px;
}

.ant-radio-button-wrapper {
  text-align: center;
  &::before {
    display: none;
  }
}
.ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.ant-radio-button-wrapper:first-child {
  border-left: none;
  border-radius: 8px 0 0 8px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0;
  border: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--color-primary-1);
  border-color: var(--color-primary-1);
  &::before,
  &::after {
    display: none;
  }
  &:hover {
    border-color: var(--color-primary-1);
  }
}

.ant-menu-inline > .ant-menu-item,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 45px;
}

.ant-radio-group-solid {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: var(--color-primary-1) !important;
    &:hover {
      background-color: var(--color-primary-1) !important;
      color: var(--color-white-1);
    }
  }
  .ant-radio-button-wrapper:hover {
    color: var(--color-primary-1);
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgb(255 169 24 / 12%);
  }
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: var(--color-primary-1);
  .anticon {
    color: var(--color-primary-1);
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: var(--color-primary-1);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--color-primary-1);
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--color-primary-1);
}

.ant-select-focused {
  .ant-select-selector {
    border-color: var(--color-primary-1) !important;
    box-shadow: 0 0 0 2px rgba(229, 188, 52, 0.2) !important;
  }
}

.ant-select-selector:hover {
  border-color: var(--color-primary-1) !important;
}

.ant-input {
  &:hover {
    border-color: var(--color-primary-1);
  }
  &:focus {
    border-color: var(--color-primary-1);
    box-shadow: 0 0 0 2px rgba(229, 188, 52, 0.2);
  }
}

.dynamic-form-bar {
  background: var(--color-gray-6);
  padding: 16px 26px;
  border-radius: 8px;
}

.image-cross-icon {
  position: absolute;
  right: 2px;
  top: 0;
  z-index: 2;
  background: var(--color-white-1);
  border-radius: 50%;
  color: var(--color-primary-1);
  cursor: pointer;
  font-size: 19px;
}
.bg-white-content {
  background: var(--color-white-1);
  padding: 50px 40px;
  overflow: hidden;
  border-radius: 16px;
}

.view-title-card-container {
  background: var(--color-white-1);
  padding: 62px 32px;
  overflow: hidden;
  border-radius: 16px;
}

.ant-select-selection-item {
  text-align: left;
}

.ant-picker {
  &:hover {
    border-color: var(--color-primary-1);
  }
}

.ant-picker-focused {
  border-color: var(--color-primary-1);
  box-shadow: 0 0 0 2px rgba(229, 188, 52, 0.2);
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: var(--color-primary-1);
}
.ant-radio-checked {
  .ant-radio-inner {
    border-color: var(--color-primary-1);
    &::after,
    &:focus {
      background: var(--color-primary-1);
    }
  }
}

.border-color-gray {
  border-color: var(--color-gray-6);
}

.ant-input-number {
  &:hover,
  &:focus {
    border-color: var(--color-primary-1);
  }

  .ant-input-number-handler-wrap {
    margin-right: 4px;
  }
}

.ant-radio-wrapper {
  padding: 8px 16px;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ant-input-number-focused {
  border-color: var(--color-primary-1) !important;

  box-shadow: 0 0 0 2px rgba(229, 188, 52, 0.2);
}

.ant-btn {
  border-radius: 8px;
}

.btn-padding {
  padding: 10px 20px;
  height: 100%;
}

.left-align {
  display: flex;
  justify-content: flex-start;
}

.w-100 {
  width: 100%;
}

.w-150 {
  width: 150px;
}

.w-116 {
  width: 116px;
}

.max-w-190 {
  max-width: 190px;
}

.bg-white {
  background: var(--color-white-1) !important;
}

.ant-input-number-disable {
  border-color: "transparent";
}

.ant-switch-checked {
  background-color: var(--color-primary-1);
}

.ant-select-selector {
  border-radius: 8px !important;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
  border-color: var(--color-primary-1) !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
  color: var(--color-primary-1) !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title {
  color: var(--color-primary-1) !important;
}
.br-16 {
  border-radius: 16px;
}

.br-12 {
  border-radius: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mr-26 {
  margin-right: 26px;
}

.h-100vh {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.mt--30 {
  margin-top: -30px !important;
}

.object-fit-cover {
  object-fit: cover;
}

.justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.margin-40 {
  margin: 40px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin-dot-item {
  background-color: var(--color-primary-1);
}
.ant-spin-text {
  color: var(--color-primary-1);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--color-primary-1);
}

.ant-upload-drag-icon {
  svg {
    color: var(--color-primary-1);
  }
}

.nth-more-btn {
  cursor: pointer;
  padding: 10px 0px 0px 10px;
  font-size: 16px;
  font-weight: 600;
}

.br-8 {
  border-radius: 8px;
  .ant-input-group-addon {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-input {
    border-radius: 0px 8px 8px 0px;
  }
}

.ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}

// Calendar------------------------------------------------

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none;
}

// Table
.MuiTable-root {
  padding: 0px 13px;
  .css-120bgvu-MuiTableHead-root th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .css-120bgvu-MuiTableHead-root th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .MuiTableHead-root {
    .MuiTableRow-root {
      background: var(--color-gray-4);
      th {
        border-bottom: 0px;
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      td {
        border-bottom: 0px;
        padding: 0.2rem 1rem;
        font-size: 12px;
      }
      &:nth-child(even) {
        background-color: var(--color-gray-4);
      }
    }
  }
}
