:root {
  --color-primary-1: rgba(102, 199, 244, 1);
  --color-primary-2: rgba(102, 199, 244, 0.9);
  --color-primary-3: rgba(102, 199, 244, 0.8);
  --color-primary-4: rgba(102, 199, 244, 0.7);
  --color-green-2: #18ba92;
  --color-green-3: #d2fbf0;
  --color-black-1: #190f04;
  --color-white-1: #ffffff;
  --color-gray-1: #585858;
  --color-gray-2: #77757f;
  --color-gray-3: #f5f5f5;
  --color-gray-4: #f9f9f9;
  --color-gray-5: #5d5d5d;
  --color-gray-5: #5d5d5d;
  --color-gray-6: #f8f8f8;
  --color-gray-7: #c2c2c2;
  --color-gray-8: #f4f4f5;
  --color-gray-input: #e9e9e9;
  --color-gray-text: #9a9a9a;
  --color-gray-stepper: #bdbdbd;
  --color-border: rgba(0, 0, 0, 0.1);
  --color-white-1: #ffffff;
  --color-green-1: #18ba92;
  --color-green-2: #19c940;
  --color-blue-1: #4a72ff;

  --color-danger: #f42829;
  --color-danger-background: #fdd4d4;
  --color-success: #18ba92;
  --color-success-background: #d2fbf0;
  --color-warning-background: #ffe2cc;

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;

  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
}
