/*
 * PreLoadPagesSpin scss File
*/

.PreLoadPagesSpin_dark{
    background-color: var(--color-black-1);
  }
  
  .PreLoadPagesSpin {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }